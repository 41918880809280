import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { Item } from '../models/item.model';
import { Subject, BehaviorSubject, combineLatest } from 'rxjs';
import { AuthService } from './auth.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ItemsService {
  query: any;
  currentItem = new BehaviorSubject<any>({});
  constructor(public afs: AngularFirestore, public afAuth: AngularFireAuth, private auth: AuthService) {
    this.query = this.afs.collection('items/covid/list', ref => ref.limit(20).orderBy('created', 'desc'))
  }

  getItemsCondition(condition) {
    return this.afs.collection('items/covid/list', ref => ref.where('npo', '==', condition).orderBy('created', 'desc')).valueChanges({idField: 'id'})
  }

  getItems() {
    return this.afs.collection('items/covid/list', ref => ref.orderBy('created', 'desc')).valueChanges({idField: 'id'});
    // return new Promise(res => {
    //   this.afs.collection('items/covid/list', ref => ref.orderBy('created', 'desc'))
    //   .ref.get().then(docs => {
    //     const resolveDocs = [];
    //     docs.forEach(doc => {
    //       resolveDocs.push(doc.data())
    //     });
    //     setTimeout(() => {
    //       return res(resolveDocs)
    //     }, 1000);
    //   });
    // })
  }

  searchItems(condition) {
    return this.afs.collection('items/covid/list', ref => ref.where('name', '==', condition)).valueChanges({idField: 'id'});
  }

  getRelatedItems(itemName, itemSurname) {
   return this.afs.collection('items/covid/list', ref => ref.where('name', '==', itemName).where('surname', '==', itemSurname).orderBy('created', 'desc')).valueChanges({idField: 'id'});
  }

  addItem(item: Item): Promise<any> {
    item.createdBy = this.auth.userData.uid;
    (item.cell) ? item.cell.replace(/\s/g, '') : '';
    return new Promise((res, rej) => {
      if (item.id) {
        return this.updateItem(item.id, item).then(() => {
          res('');
        }).catch(err => {
          console.log('error updating item', err)
          rej('')
        })
      } else {
        item.created = new Date();
        return this.afs.collection('items/covid/list').add(item).then(() => {
          res('');
        }).catch(err => {
          console.log('error adding item', err)
          rej()
        })
      }
    })
  }

  filterDateRange(toDate, fromDate) {
    return new Promise(res => {
      let start = new Date(fromDate.year, fromDate.month - 1, fromDate.day, 0, 0, 0, 0);
      let end = new Date(toDate.year, toDate.month - 1, toDate.day, 0, 0, 0, 0);
      return this.afs.collection(`items/covid/list`).ref.where('created', '>=', start).where('created', '<=', end).get().then(docs => {
        if (docs.size > 0) {

          let count = 0;
          const itemsList = [];
          docs.forEach(item => {
              itemsList.push({id: item.id ,...item.data()})
              count++;
              if (count === docs.size) {
                res(itemsList)
              }
            });

        } else {
          res([])
        }

      })
    })
  }

  getItem(itemId) {
    return this.afs.doc(`items/covid/list/${itemId}`).valueChanges();
  }

  updateItem(itemId, item: Item) {
    item.modifiedBy = this.auth.userData.uid;
    delete item.id;
    return this.afs.doc(`items/covid/list/${itemId}`).update(item);
  }

  removeItem(item) {
    return this.afs.doc(`items/covid/list/${item.id}`).delete();
  }

  setCurrentItem(item) {
    this.currentItem.next(item)
  }

  getReports(npo) {
    if (npo !== 'all') {
      return this.afs.collection('items/covid/list', ref => ref.orderBy('created', 'desc').where('npo', '==', npo)).valueChanges({idField: 'id'});
    } else {
      return this.afs.collection('items/covid/list', ref => ref.orderBy('created', 'desc')).valueChanges({idField: 'id'});
    }
  }

}
