import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { resolve } from 'url';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class MediaService {
  constructor(public afs: AngularFirestore, public authService: AuthService) { }

  getLatestSeriesDownloaded() {
    return new Promise((res, rej) => {

    });
  }

  saveItemToWatchList(item) {
    const user = JSON.parse(localStorage.getItem('user'));
    item.active = true;
    item.addedDate = Date.now();
    return this.afs.doc(`users/${user.uid}/watchList/${item.tvdbid}`).set(item, {merge: true});
  }

  getUserWatchList() {
    const user = JSON.parse(localStorage.getItem('user'));
    return this.afs.collection(`users/${user.uid}/watchList`, ref => ref.where('active', '==', true)).valueChanges({idField: 'id'});
  }

  removeItemFromWatchList(item) {
    const user = JSON.parse(localStorage.getItem('user'));
    item.active = false;
    return this.afs.doc(`users/${user.uid}/watchList/${item.tvdbid}`).set(item, {merge: true});
  }

  addToUserWatched(item) {
    const user = JSON.parse(localStorage.getItem('user'));
    const update = {};
    update[item.episode] = 'watched';
    return this.afs.doc(`users/${user.uid}/watchList/${item.tvdbid}/seasons/${item.season}`).set(update, {merge: true});
  }

}
