import { Injectable, NgZone } from '@angular/core';
import { User } from '../services/user';
import { auth } from 'firebase/app';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore, AngularFirestoreDocument } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable, of } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class AuthService {
  allowed = [
    'cj@noldor.co.za',
    'rpilodia123@gmail.com',
    'judy@feedachild.co.za',
    'hasiekies@gmail.com',
    'tgagiano3@gmail.com',
    'chantelesterhuizen@gmail.com',
    'debbielloyd1973@gmail.com',
    'Olga123@live.co.za',
    'theunis@noldor.co.za',
    'lizette@hrcity.co.za',
    'helda69@mweb.co.za',
    'rosecare566@gmail.com',
    'pikkels09@gmail.com',
    'ammeliastruwig@ymail.com',
    'yolandaelof@gmail.com',
    'charenvk@gmail.com',
    'Kotze.helena@gmail.com',
    'boschjanine@yahoo.com',
    'wilmiedevilliers@gmail.com',
    'francovdm@wateras.co.za',
    'martlie@ctpbussolutions.com',
    'cycc@cmrmid.co.za',
    'jouwindisuit@gmail.com',
    'zonicamostert@gmail.com',
    'Lizlweimer@hotmail.com',
    'almalhelpalmalvlerke@gmail.com',
    'coetzeejolandri@gmail.com',
    'annerieweber@gmail.com'
  ];

  userData: any; // Save logged in user data
  user: Observable<any>;
  constructor(
    public afs: AngularFirestore,   // Inject Firestore service
    public afAuth: AngularFireAuth, // Inject Firebase auth service
    public router: Router,
    public ngZone: NgZone // NgZone service to remove outside scope warning
  ) {
    /* Saving user data in localstorage when
    logged in and setting up null when logged out */
    this.user = this.afAuth.authState;
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.userData = user;
        localStorage.setItem('user', JSON.stringify(this.userData));
        JSON.parse(localStorage.getItem('user'));
      } else {
        localStorage.setItem('user', null);
        JSON.parse(localStorage.getItem('user'));
      }
    });
  }

  // Sign in with email/password
  SignIn(email, password) {
    return this.afAuth.auth.signInWithEmailAndPassword(email.toLowerCase(), password)
    .then((result) => {
      this.ngZone.run(() => {
        this.router.navigate(['dashboard']);
      });
      this.SetUserData(result.user);
    }).catch((error) => {
      window.alert(error.message);
    });
  }

  // Sign up with email/password
  SignUp(email, password) {
    return this.afAuth.auth.createUserWithEmailAndPassword(email.toLowerCase(), password)
        .then((result) => {
          /* Call the SendVerificaitonMail() function when new user sign
          up and returns promise */
          this.SendVerificationMail();
          this.SetUserData(result.user);
        }).catch((error) => {
          window.alert(error.message);
        });
  }

  addManualUser(userDetails: any) {
    const password = Math.random().toString(36).slice(2);
    return this.afAuth.auth.createUserWithEmailAndPassword(userDetails.email.toLowerCase(), password)
    .then((result: any) => {
      /* Call the SendVerificaitonMail() function when new user sign
      up and returns promise */
      result.user.npo = userDetails.npo;
      result.user.pwd = password;
      return this.SetUserData(result.user);
    }).catch((error) => {
      window.alert(error.message);
    });
  }

  // Send email verfificaiton when new user sign up
  SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
      .then(() => {
        this.router.navigate(['verify-email-address']);
      });
  }

  // Reset Forggot password
  ForgotPassword(passwordResetEmail) {
    return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail.toLowerCase())
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
      }).catch((error) => {
        window.alert(error);
      });
  }

  // Returns true when user is looged in and email is verified
  get isLoggedIn(): boolean {
    const user = JSON.parse(localStorage.getItem('user'));
    return (user !== null && user.emailVerified !== false) ? true : false;
  }

  // Sign in with Google
  GoogleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }

  // Auth logic to run auth providers
  AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
      .then((result) => {
        this.ngZone.run(() => {
          this.router.navigate(['dashboard']);
        });
        this.SetUserData(result.user);
      }).catch((error) => {
        window.alert(error);
      });
  }

  /* Setting up user data when sign in with username/password,
  sign up with username/password and sign in with social auth
  provider in Firestore database using AngularFirestore + AngularFirestoreDocument service */
  SetUserData(user) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    const userData: User = {
      active: true,
      uid: user.uid,
      email: user.email,
      displayName: user.displayName,
      photoURL: user.photoURL,
      emailVerified: user.emailVerified,
      category: '',
      npo: (user.npo) ? user.npo:''
    };
    return userRef.set(userData, {
      merge: true
    });

  }

  // Sign out
  SignOut() {
    return this.afAuth.auth.signOut().then(() => {
      localStorage.removeItem('user');
      this.router.navigate(['sign-in']);
    });
  }

}
