import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class UsersService {
  selectedUser = new BehaviorSubject({});

  constructor(public afs: AngularFirestore, public afAuth: AngularFireAuth) { }

  getUsers() {
    return this.afs.collection('users').valueChanges({ idField: 'id' });
  }

  getUser(userId) {
    return this.afs.doc(`users/${userId}`).valueChanges();
  }

  setUserActive(userId) {
    return this.afs.doc(`users/${userId}`).set({active: true}, {merge: true});
  }

  updateUser(userId, user) {
    return this.afs.doc(`users/${userId}`).update(user);
  }

  setSelectedUser(user) {
    return new Promise(res => {
      this.selectedUser.next(user);
      res()
    })
  }

  addManualUser (user) {
    user.status = 'pending';
    return this.afs.collection(`pending`).add(user);
  }
}
