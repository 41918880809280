import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class BlogService {

  constructor(public afs: AngularFirestore, public afAuth: AngularFireAuth) { }

  getPosts(siteId) {
    const sitesRef: AngularFirestoreCollection<any> = this.afs.collection(`sites/${siteId}/blog/posts`, ref => ref.where('active', '==', true));
    return sitesRef.valueChanges({ idField: 'id' });
  }

  getPost(siteId, postid) {
    const sitesRef: AngularFirestoreDocument<any> = this.afs.doc(`sites/${siteId}/posts/${postid}`);
    return sitesRef.valueChanges();
  }

  addPost(siteId, post) {
    post['active'] = true;
    const sitesRef: AngularFirestoreCollection<any> = this.afs.collection(`sites/${siteId}/blog/posts`);
    return sitesRef.add(post);
  }

  updatePost(siteId, postId, post) {
    const sitesRef: AngularFirestoreDocument<any> = this.afs.doc(`sites/${siteId}/posts/${postId}`);
    return sitesRef.update(post);
  }

  deletePost(siteId, postId, post) {
    post['active'] = false;
    const sitesRef: AngularFirestoreDocument<any> = this.afs.doc(`sites/${siteId}/posts/${postId}`);
    return sitesRef.update(post);
  }

}
