import { Component, OnInit } from '@angular/core';
import { AuthService } from './shared/services/auth.service';
import { Router, NavigationEnd } from '@angular/router';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  loadingData = true;
  constructor(public authService: AuthService, private router: Router) {}
  year = moment().format('YYYY')
  title = 'COVID-19 Support';

  ngOnInit(): void {
    this.loadingData = true;

    this.router.events.subscribe((route) => {
      if (route instanceof NavigationEnd) {
        this.loadingData = false;
      }
  });
  }
}
