// export const environment = {
//   "production": false,
//   "firebase": {
//     "apiKey": "AIzaSyDaSJddJSq7MOC_7OfTVqiKmhjBiX2mFu0",
//     "authDomain": "covid-support-db0ea.firebaseapp.com",
//     "databaseURL": "https://covid-support-db0ea.firebaseio.com",
//     "projectId": "covid-support-db0ea",
//     "storageBucket": "covid-support-db0ea.appspot.com",
//     "messagingSenderId": "560868322611",
//     "appId": "1:560868322611:web:b6a0a8e5518dc616d82246",
//     "measurementId": "G-RQ6XBC3Q6N"
//   },
// };

export const environment = {
  "production": false,
  "firebase": {
    "apiKey": "AIzaSyAR8vkXCzsSo_hV4HyxALzfqNVFbvS11aQ",
    "authDomain": "covid-support-nldr.firebaseapp.com",
    "databaseURL": "https://covid-support-nldr.firebaseio.com",
    "projectId": "covid-support-nldr",
    "storageBucket": "covid-support-nldr.appspot.com",
    "messagingSenderId": "362433834952",
    "appId": "1:362433834952:web:4f9b4427dc01d55be99afd",
    "measurementId": "G-23EM8NPJBR"
  },
};
