import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class SitesService {

constructor(public afs: AngularFirestore, public afAuth: AngularFireAuth) { }

// GET USER SITES
getSites(userId) {
  const sitesRef: AngularFirestoreCollection<any> = this.afs.collection(`users/${userId}/sites`, ref => ref.where('active', '==', true));
  return sitesRef.valueChanges({idField: 'id'});
}

// GET SITE
getSite(siteId) {
  const sitesRef: AngularFirestoreDocument<any> = this.afs.doc(`sites/${siteId}`);
  return sitesRef.valueChanges();
}

// ADD A NEW SITE
addSite(usersId, site) {
  site['active'] = true;

  // ADD SITE TO SITES COLLECTION
  const addSite = () => new Promise((res, rej) => {
    const sitesRef: AngularFirestoreCollection<any> = this.afs.collection(`sites`);
    sitesRef.add(site).then((result) => res(result.id));
  });
  // ADD SITE TO USER SITES COLLECTION
  const addUserSite = (siteId) => new Promise((res, rej) => {
    const userSitesRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${usersId}/sites/${siteId}`);
    userSitesRef.set(site).then(() => res());
  });
  const handlePromises = async () => {
    const addSitePromise = await addSite();
    const addUserSitePromise = await addUserSite(addSitePromise);
    return Promise.all([addSitePromise, addUserSitePromise]);
  };
  return handlePromises();

}

// UPDATE SITE
updateSite(userId, siteId, site) {
  const sitesRef = this.afs.doc(`site/${siteId}`).update(site);
  const userSitesRef = this.afs.doc(`users/${userId}/sites/${siteId}`).update(site);
  return Promise.all([sitesRef, userSitesRef]);
}

// DELETE SITE
deleteSite(userId, siteId, site) {
  site['active'] = false;
  const sitesRef = this.afs.doc(`site/${siteId}`).update(site);
  const userSitesRef = this.afs.doc(`users/${userId}/sites/${siteId}`).update(site);
  return Promise.all([sitesRef, userSitesRef]);
}

}
